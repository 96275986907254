import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'dpl/common/components/Icon';
import TooltipWrapper from 'dpl/common/tooltip/components/TooltipWrapper';
import { HEALTH_TESTING_GUIDE_PATH } from 'dpl/shared/constants/urls';

const tooltipText = (
  <span>
    Health testing is performed on breeding dogs to prevent heritable conditions
    in puppies.&nbsp;
    <a
      href={HEALTH_TESTING_GUIDE_PATH}
      className="underline"
      target="_blank"
      rel="noopener noreferrer"
    >
      Learn more.
    </a>
  </span>
);

export default function HealthTestingTooltip({ tipClass }) {
  return (
    <TooltipWrapper className={tipClass} minWidth="300px" title={tooltipText}>
      <Icon name="info" className="ml2 mt1" />
    </TooltipWrapper>
  );
}

HealthTestingTooltip.propTypes = {
  tipClass: PropTypes.string
};

HealthTestingTooltip.defaultProps = {
  tipClass: ''
};
