import {
  BREEDER_ACTIVE_MESSAGES_PATH,
  BREEDER_APPLICANT_MESSAGES_PATH,
  BREEDER_ARCHIVED_MESSAGES_PATH,
  BREEDER_OWNER_MESSAGES_PATH,
  BREEDER_WAITLIST_MESSAGES_PATH
} from 'dpl/shared/constants/urls';

export const CONVERSATION_STATES = {
  ARCHIVED: 'archived',
  ACTIVE: 'active',
  UNREAD: 'unread',
  APPLICANTS: 'applicants',
  WAITLIST: 'waitlist',
  OWNERS: 'owners'
};

export const CONVERSABLE_TYPES = {
  BREEDER: 'BreederProfile',
  BUYER: 'BuyerProfile'
};

export const CONVERSATION_FILTERS = {
  [CONVERSATION_STATES.ACTIVE]: {
    to: BREEDER_ACTIVE_MESSAGES_PATH,
    headline: 'Inbox',
    title: 'All messages'
  },
  [CONVERSATION_STATES.APPLICANTS]: {
    to: BREEDER_APPLICANT_MESSAGES_PATH,
    title: 'Applicants'
  },
  [CONVERSATION_STATES.WAITLIST]: {
    to: BREEDER_WAITLIST_MESSAGES_PATH,
    title: 'Waitlist'
  },
  [CONVERSATION_STATES.OWNERS]: {
    to: BREEDER_OWNER_MESSAGES_PATH,
    title: 'Owners'
  },
  [CONVERSATION_STATES.ARCHIVED]: {
    to: BREEDER_ARCHIVED_MESSAGES_PATH,
    title: 'Archived'
  }
};
