import PropTypes from 'prop-types';
import React from 'react';

import ClickableDiv from 'dpl/common/components/ClickableDiv';
import HorizontalSlider from 'dpl/components/HorizontalSlider';
import SmartImage from 'dpl/components/SmartImage';
import { openPuppyPage } from 'dpl/puppy_page/utils/helpers';
import { capitalize } from 'dpl/shared/utils';
import { BreederProfilePuppiesPropTypes } from 'dpl/shared/utils/customPropTypes';
import Badge from 'dpl/common/design_system/Badge';
import ImagePlaceholder from 'dpl/puppy_page/components/ImagePlaceholder';

function PuppyCard({ puppy }) {
  const smartImageProps = {
    Tag: 'div',
    className: 'w-100 bg-center cover br3 br--top',
    crop: true,
    loadingClass: 'bg-light-gray',
    height: '210px',
    allowContextMenu: true
  };

  const unavailable = puppy.reserved || puppy.snoozed;
  const puppyDescription = [
    capitalize(puppy.gender || ''),
    puppy.status_with_date
  ]
    .filter(Boolean)
    .join(' • ');

  return (
    <ClickableDiv
      onClick={() => {
        openPuppyPage({ puppyId: puppy.id });
      }}
      data-test-id={`puppy-${puppy.id}`}
    >
      <div className="BreederProfilePuppyCard h-100 flex flex-column br3 ba b--light-gray overflow-hidden">
        {puppy.profile_photo_url ? (
          <SmartImage src={puppy.profile_photo_url} {...smartImageProps} />
        ) : (
          <ImagePlaceholder className="bg-branch-light" />
        )}
        <div className="pv4 ph4">
          <div className="flex items-center justify-between">
            <p className="font-serif font-20 truncate">{puppy.name}</p>
            <Badge size="small" color={unavailable ? 'gray' : 'stroll'}>
              {unavailable ? 'Reserved' : 'Available'}
            </Badge>
          </div>

          <p className="font-16 secondary mv1">{puppyDescription}</p>
          <p className="font-16 secondary">{puppy.breed_name}</p>
        </div>
      </div>
    </ClickableDiv>
  );
}

PuppyCard.propTypes = {
  puppy: PropTypes.shape(BreederProfilePuppiesPropTypes).isRequired
};

export default function BreederProfileCurrentPuppiesSection({ validPuppies }) {
  const containsAdults = validPuppies.find(pup => pup.adult);
  const title = containsAdults ? 'Puppies and older dogs' : 'Puppies';

  return (
    <section className="BreederProfileCurrentPuppiesSection bg-subtle-light-gray pv12 pv20-md">
      <div className="container-xxl">
        <h3 className="mb6 mb10-md font-24 md:font-40 lh-title">{title}</h3>

        <HorizontalSlider
          className="BreederProfilePuppiesSlider"
          containerClassName="BreederProfilePuppiesSlider__container flex flex-nowrap w-100 gap-8 md:gap-16 lg:gap-16"
          iconClassName="f3 bg-white o-80 br-100 box-shadow-strong b black pv2 ph2"
          iconName="fetch-chevron"
        >
          {validPuppies.map(puppy => (
            <div key={puppy.id} className="flex ws-normal">
              <PuppyCard puppy={puppy} />
            </div>
          ))}
        </HorizontalSlider>
      </div>
    </section>
  );
}

BreederProfileCurrentPuppiesSection.propTypes = {
  validPuppies: PropTypes.arrayOf(
    PropTypes.shape(BreederProfilePuppiesPropTypes)
  ).isRequired
};
