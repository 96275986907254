import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import __DEPRECATED_defineResources from 'dpl/shared/fetching/deprecated/defineResources';
import { pushParams } from 'dpl/actions/query_params';
import { LITTER_ID } from 'dpl/constants/query_params';
import { littersResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import withToggle from 'dpl/decorators/withToggle';
import BreederProfileLittersSection from 'dpl/components/BreederProfile/Show/BreederProfileLittersSection';

function BreederProfileLittersSectionContainer(props) {
  return <BreederProfileLittersSection {...props} />;
}

export const littersResourceMapper = compose(
  __DEPRECATED_defineResources(({ server: { breederProfileId } }) => ({
    littersData: littersResourceDefinition(breederProfileId, {
      exclude_adult: true
    })
  })),
  connect((state, { littersData }) => ({ litters: littersData.value.data }))
);

export default compose(
  withToggle({ propName: 'isExpanded' }),
  littersResourceMapper,
  connect(null, {
    onLitterClick: litterId =>
      pushParams(
        {
          [LITTER_ID]: litterId
        },
        { hash: true }
      )
  })
)(BreederProfileLittersSectionContainer);
