import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { groupBy } from 'dpl/shared/utils';
import SmartImage from 'dpl/components/SmartImage';
import ClampedMultiLineText from 'dpl/common/components/ClampedMultiLineText';
import TransitionMenu from 'dpl/common/components/TransitionMenu';

import HealthTestingTooltip from '../HealthTestingTooltip';

function BreederProfileHealthTierSection({ breedHealthTier }) {
  return (
    <div className="BreederProfileHealthTierSection flex items-center h-100">
      <div>
        <p>Breeder-Reported Testing Meets:</p>
        <p className="fw-medium f4">{breedHealthTier.name} Level</p>
      </div>
    </div>
  );
}

BreederProfileHealthTierSection.propTypes = {
  breedHealthTier: PropTypes.shape({
    name: PropTypes.string.isRequired,
    level: PropTypes.number
  }).isRequired
};

export function BreederProfileHealthBadgeSection({
  breederDisplayName,
  breedHealthTier,
  learnMoreUrl,
  pluralizedBreedName
}) {
  const displayHealthTier = Boolean(breedHealthTier);
  const textContent = (
    <div className="f2 f3-lg">
      <p>
        {breederDisplayName} reports to performing the health tests below on
        their breeding dogs. Ask your breeder about the tests performed on the
        parents of your litter.&nbsp;
        <a
          className="underline"
          href={learnMoreUrl}
          rel="noopener noreferrer"
          target="_blank"
        >
          Learn more
        </a>
        &nbsp;about health testing for {pluralizedBreedName}.
      </p>
    </div>
  );

  return (
    <div className="mb12">
      {displayHealthTier ? (
        <div className="br3 overflow-hidden ba b--light-gray">
          <div className="row no-gutters">
            <div className="col-md-auto">
              <div className="pr6 pl4 pv4 h-100">
                <BreederProfileHealthTierSection
                  breedHealthTier={breedHealthTier}
                />
              </div>
            </div>
            <div className="col-md">
              <div className="ph6 pv4 h-100 flex items-center">
                {textContent}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="br3 ph4 pv4 ba b--light-gray">
          <div>{textContent}</div>
        </div>
      )}
    </div>
  );
}

BreederProfileHealthBadgeSection.propTypes = {
  breederDisplayName: PropTypes.string.isRequired,
  breedHealthTier: PropTypes.shape({
    name: PropTypes.string.isRequired,
    level: PropTypes.number
  }),
  learnMoreUrl: PropTypes.string.isRequired,
  pluralizedBreedName: PropTypes.string.isRequired
};

BreederProfileHealthBadgeSection.defaultProps = {
  breedHealthTier: null
};

export default class BreederProfileHealthTestsSection extends Component {
  state = {
    selectedMenuIdx: 0
  };

  handleMenuItemClick = (e, idx) => this.setState({ selectedMenuIdx: idx });

  render() {
    const { breederDisplayName, breedsWithHealthInfo } = this.props;
    const { selectedMenuIdx } = this.state;

    const selectedBreed = breedsWithHealthInfo[selectedMenuIdx];

    const {
      health_tests: healthTests,
      pluralized_breed_name: pluralizedBreedName,
      health_tier: breedHealthTier,
      health_testing_guide_path: learnMoreUrl
    } = selectedBreed;

    return (
      <section className="BreederProfileHealthTestsSection">
        <div className="flex">
          <h5 className="mb6 d-none d-lg-block f5">Parent dog health tests</h5>
          <HealthTestingTooltip />
        </div>
        <TransitionMenu
          className="bb b--light-gray fw-medium"
          itemClassName="pb3 silver"
          items={breedsWithHealthInfo.map(b => b.breed_name)}
          selectedIdx={selectedMenuIdx}
          onItemClick={this.handleMenuItemClick}
        />
        {selectedBreed && (
          <div className="pv6">
            <BreederProfileHealthBadgeSection
              breederDisplayName={breederDisplayName}
              breedHealthTier={breedHealthTier}
              learnMoreUrl={learnMoreUrl}
              pluralizedBreedName={pluralizedBreedName}
            />
            <div className="overflow-hidden">
              <div className="row">
                {Object.values(
                  groupBy(healthTests, 'health_test_category.id')
                ).map((tests, idx) => {
                  const category = tests[0].health_test_category;

                  return (
                    <div className="col-md-6 mb10-md mb4" key={idx}>
                      <div className="row slim-gutters flex-nowrap ph5-md">
                        <div className="col-auto tc">
                          <SmartImage
                            lazy
                            src={category.photo_url}
                            width="104px"
                            className="br-100 mw-100 bg-near-white"
                          />
                          <p className="fw-medium mt2">{category.name}</p>
                        </div>
                        <div className="col-8">
                          <div className="mb2">
                            <ClampedMultiLineText
                              numOfLines={2}
                              lineHeight={24}
                              text={tests.map(({ name }) => name).join(', ')}
                              moreText="+ more"
                            />
                          </div>
                          <div className="f2">
                            <ClampedMultiLineText
                              numOfLines={5}
                              lineHeight={20}
                              text={category.description}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </section>
    );
  }
}

BreederProfileHealthTestsSection.propTypes = {
  breederDisplayName: PropTypes.string.isRequired,
  breedsWithHealthInfo: PropTypes.arrayOf(
    PropTypes.shape({
      breed_name: PropTypes.string.isRequired,
      pluralized_breed_name: PropTypes.string.isRequired,
      health_testing_guide_path: PropTypes.string,
      health_tier: PropTypes.shape({
        name: PropTypes.string.isRequired,
        level: PropTypes.number
      }),
      health_tests: PropTypes.arrayOf(
        PropTypes.shape({
          health_test_category: PropTypes.shape({
            id: PropTypes.number.isRequired
          }).isRequired
        })
      ).isRequired
    })
  ).isRequired
};
