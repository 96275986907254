import React from 'react';
import PropTypes from 'prop-types';

import ClampedMultiLineText from 'dpl/common/components/ClampedMultiLineText';
import Icon from 'dpl/common/components/Icon';
import SmartImage from 'dpl/components/SmartImage';

import breederBadgeImg from 'dpl/assets/breeder_badge.svg';

export default function BreederProfileIntroductionSection({
  introduction,
  breederFullName,
  breederDisplayName,
  breederFunFacts,
  breederPhotoUrl,
  deliveryMethods,
  cityState,
  priceRange
}) {
  return (
    <section className="BreederProfileIntroductionSection container-xxl pv0 pv6-sm pv12-lg">
      <div className="row">
        <div className="col-md-8">
          <div className="flex align-items-center align-items-md-start">
            <p className="subheader mb4-md pr2 flex-auto">
              Hi, I&apos;m {breederFullName}
            </p>
            <div className="d-md-none">
              <SmartImage
                Tag="div"
                lazy
                resize
                src={breederPhotoUrl}
                width="56px"
                height="56px"
                className="br-100 bg-near-white cover bg-center"
              />
            </div>
          </div>
          <ClampedMultiLineText
            text={introduction}
            numOfLines={5}
            lineHeight={24}
          />
          <div className="d-md-none mt6">
            <p className="fw-medium mb4">Key Details</p>
            {priceRange && (
              <div className="flex mb4">
                <Icon name="money" />
                <p className="pl4">{priceRange}</p>
              </div>
            )}

            {cityState && (
              <div className="flex mb4">
                <Icon name="location-pin" />
                <p className="pl4">{cityState}</p>
              </div>
            )}

            {deliveryMethods && (
              <div className="flex mb4">
                <Icon name="plane" />
                <p className="pl4">{deliveryMethods}</p>
              </div>
            )}
          </div>
          {breederFunFacts.length > 0 && (
            <div className="mt10 mt16-md">
              <p className="subheader mb4">
                What We Love About {breederDisplayName}
              </p>
              <ul>
                {breederFunFacts.map((ff, idx) => (
                  <li className="flex mb4" key={idx}>
                    <Icon
                      name="asterisk-16"
                      className="purple mr2 mt2"
                      width="12px"
                      height="12px"
                    />
                    <p>{ff}</p>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className="col-4 d-none d-md-block tc">
          <SmartImage
            lazy
            alt="Good Breeder Badge"
            forceOriginalSrc
            src={breederBadgeImg}
            width="148px"
            height="148px"
            className="mw-100"
          />
        </div>
      </div>
    </section>
  );
}

BreederProfileIntroductionSection.propTypes = {
  introduction: PropTypes.string.isRequired,
  breederFullName: PropTypes.string.isRequired,
  breederDisplayName: PropTypes.string.isRequired,
  breederFunFacts: PropTypes.arrayOf(PropTypes.string).isRequired,
  breederPhotoUrl: PropTypes.string.isRequired,
  deliveryMethods: PropTypes.string.isRequired,
  cityState: PropTypes.string.isRequired,
  priceRange: PropTypes.string.isRequired
};
