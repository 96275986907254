import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { groupBy } from 'dpl/shared/utils';
import SmartImage from 'dpl/components/SmartImage';
import ClampedMultiLineText from 'dpl/common/components/ClampedMultiLineText';
import TransitionMenu from 'dpl/common/components/TransitionMenu';
import { BreederProfileHealthBadgeSection } from 'dpl/components/BreederProfile/Show/BreederProfileHealthTestsSection';

import HealthTestingTooltip from '../HealthTestingTooltip';

export default class MobileBreederProfileHealthTestsSection extends Component {
  static propTypes = {
    breederDisplayName: PropTypes.string.isRequired,
    breedsWithHealthInfo: PropTypes.arrayOf(
      PropTypes.shape({
        breed_name: PropTypes.string.isRequired,
        pluralized_breed_name: PropTypes.string.isRequired,
        health_tier: PropTypes.number,
        health_testing_guide_path: PropTypes.string,
        health_tests: PropTypes.arrayOf(
          PropTypes.shape({
            health_test_category: PropTypes.shape({
              id: PropTypes.number.isRequired
            }).isRequired
          })
        ).isRequired
      })
    ).isRequired
  };

  state = {
    selectedMenuIdx: 0
  };

  handleMenuItemClick = (e, idx) => this.setState({ selectedMenuIdx: idx });

  render() {
    const { breederDisplayName, breedsWithHealthInfo } = this.props;
    const { selectedMenuIdx } = this.state;

    const selectedBreed = breedsWithHealthInfo[selectedMenuIdx];
    const {
      health_testing_guide_path: learnMoreUrl,
      health_tests: healthTests,
      pluralized_breed_name: pluralizedBreedName,
      health_tier: breedHealthTier
    } = selectedBreed;

    return (
      <section className="MobileBreederProfileHealthTestsSection">
        <HealthTestingTooltip tipClass="fr" />
        <TransitionMenu
          className="overflow-x-auto"
          items={breedsWithHealthInfo.map(b => b.breed_name)}
          selectedIdx={selectedMenuIdx}
          onItemClick={this.handleMenuItemClick}
          itemClassName="silver"
        />
        {selectedBreed && (
          <div className="pv6">
            <BreederProfileHealthBadgeSection
              breederDisplayName={breederDisplayName}
              breedHealthTier={breedHealthTier}
              learnMoreUrl={learnMoreUrl}
              pluralizedBreedName={pluralizedBreedName}
            />

            {Object.values(groupBy(healthTests, 'health_test_category.id')).map(
              (tests, idx) => {
                const category = tests[0].health_test_category;

                return (
                  <div className="mb4" key={idx}>
                    <div className="mb2 flex align-items-center">
                      <div className="mr4">
                        <SmartImage
                          height="56px"
                          lazy
                          src={category.photo_url}
                          width="56px"
                          className="br-100 mw-100 bg-near-white"
                        />
                      </div>
                      <ClampedMultiLineText
                        numOfLines={2}
                        lineHeight={24}
                        text={`${category.name} - ${tests
                          .map(({ name }) => name)
                          .join(', ')}`}
                        moreText="+ more"
                      />
                    </div>
                    <div className="mid-gray">
                      <ClampedMultiLineText
                        numOfLines={5}
                        lineHeight={24}
                        text={category.description}
                      />
                    </div>
                  </div>
                );
              }
            )}
          </div>
        )}
      </section>
    );
  }
}
