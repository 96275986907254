import React from 'react';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';

import Testimonial from 'dpl/testimonials/components/Testimonial';
import BuyerDisplay from 'dpl/testimonials/components/BuyerDisplay';
import usePaginatedTestimonials from 'dpl/components/BreederProfile/hooks/usePaginatedTestimonials';
import LoadingWrapper from 'dpl/components/LoadingWrapper';
import BreederProfileVerifiedOwnersPhotos from 'dpl/components/BreederProfile/Show/BreederProfileVerifiedOwnersPhotos';
import { isGreaterThanBreakpoint } from 'dpl/util/grid';
import { useTestimonialModal } from 'dpl/containers/BreederProfile/Index/BreederProfileTestimonialModal';
import { createBreederProfilePaginationAction } from 'dpl/containers/BreederProfile/Show/BreederProfilePhotoAlbumContainer';

export const testimonialCountSelector = state => state.server.testimonialsCount;

export const testimonialsSectionVisibilitySelector = state =>
  testimonialCountSelector(state) > 0;

const MAX_SHOWN = 5;
const CURRENT_PAGE = 1;

export default function BreederProfileTestimonialsSection() {
  const { isLoading, testimonials, meta } = usePaginatedTestimonials(
    MAX_SHOWN,
    CURRENT_PAGE
  );
  const isGreaterThanSmall = isGreaterThanBreakpoint('sm');
  const { open: openTestimonialModal } = useTestimonialModal();
  const dispatch = useDispatch();

  return (
    <section className="BreederProfileTestimonialsSection">
      <h5 className="mb4 mb6-md f4">Owner stories</h5>
      <div
        className={classnames({
          'pb8 bb b--lightest-gray': isGreaterThanSmall
        })}
      >
        <BreederProfileVerifiedOwnersPhotos />
      </div>
      <LoadingWrapper isLoading={isLoading}>
        {() => (
          <>
            {testimonials.map((testimonial, idx) => (
              <div
                key={testimonial.id}
                className={classnames('pv6', {
                  'bt b--lightest-gray': idx > 0
                })}
              >
                <div className="row">
                  <div className="col-md-3 pb2 pb-md-0">
                    <BuyerDisplay
                      user={testimonial.buyer_profile}
                      displayName={testimonial.buyer_profile.name}
                      date={testimonial.submitted_at}
                      variant={isGreaterThanSmall ? 'stacked' : 'inline'}
                    />
                  </div>
                  <div className="col-md-9">
                    <Testimonial
                      text={testimonial.experience}
                      photographs={testimonial.photographs}
                      clampText
                      onShowMoreClick={
                        testimonial.instagram &&
                        (() => openTestimonialModal(testimonial.id))
                      }
                      onImageClick={id =>
                        dispatch(createBreederProfilePaginationAction(id))
                      }
                    />
                  </div>
                </div>
              </div>
            ))}
            {meta.total_pages > 1 && (
              <div className="bt b--lightest-gray pt6">
                <button
                  className="button button--secondary fw-medium w-100 w-auto-ns justify-center align-items-center"
                  type="button"
                  onClick={() => openTestimonialModal()}
                >
                  Show all {meta.total_count} owner stories
                </button>
              </div>
            )}
          </>
        )}
      </LoadingWrapper>
    </section>
  );
}
