import { useSelector } from 'react-redux';

export default function usePuppiesAndLitterAvailabilityCheck(breedId = null) {
  const availablePuppyAndFutureLitterByBreedId = useSelector(
    ({ server }) => server.availablePuppyAndFutureLitterByBreedId
  );

  let availablePuppiesForBreed = false;

  if (!availablePuppyAndFutureLitterByBreedId) {
    return availablePuppiesForBreed;
  }

  if (breedId && typeof availablePuppyAndFutureLitterByBreedId === 'object') {
    availablePuppiesForBreed = Boolean(
      availablePuppyAndFutureLitterByBreedId[breedId]
    );
  } else if (availablePuppyAndFutureLitterByBreedId) {
    availablePuppiesForBreed = Object.values(
      availablePuppyAndFutureLitterByBreedId
    ).some(Boolean);
  }

  return { availablePuppiesForBreed };
}
