import { ANALYTICS_EVENTS } from 'dpl/constants/analytics';
import { sendAnalyticsEvent } from 'dpl/util/analytics';

export function removeAllManyToManyAssociations(associations) {
  return associations.reduce((acc, val) => {
    const isPersisted = Boolean(val.id);
    if (isPersisted) {
      // if persisting record, mark for destroy
      return [...acc, { ...val, _destroy: true }];
    }
    return acc;
  }, []);
}

export function trackBuyerApplicationQuestionView(
  title,
  properties,
  name = title
) {
  sendAnalyticsEvent(ANALYTICS_EVENTS.BUYER_APPLICATION_QUESTION_VIEWED, {
    questionName: name,
    questionTitle: title,
    ...properties
  });
}
