import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { isMobileUA } from 'dpl/shared/utils';
import {
  FOLLOWABLE_TYPES,
  FAVORITE_BUTTON_VARIANTS
} from 'dpl/favorites/utils/constants';
import { BREED_ID } from 'dpl/constants/query_params';
import FavoriteButton from 'dpl/favorites/components/FavoriteButton';
import { PORTAL_MOUNT_EL } from 'dpl/common/tooltip/components/FloatingTooltipWrapper';

const IS_MOBILE_UA = isMobileUA();

function BreederProfileFavoriteButtonWrapper({ children }) {
  return (
    <>
      {!IS_MOBILE_UA ? (
        <div className="BreederProfileFixedHeader__button br b--light-gray">
          <div className="h-100 flex justify-center items-center">
            {children}
          </div>
        </div>
      ) : (
        <div className="ml2">{children}</div>
      )}
    </>
  );
}

BreederProfileFavoriteButtonWrapper.propTypes = {
  children: PropTypes.node.isRequired
};

export default function BreederProfileFavoriteButton() {
  const breedId = useSelector(({ queryParams }) =>
    Number(queryParams[BREED_ID])
  );
  const {
    availablePuppyCountByBreedId,
    breederBreeds,
    breederDisplayName: breederProfileName,
    breederPreferredStatus,
    breederProfileId,
    deliveryMethods
  } = useSelector(({ server }) => server);

  const { id: breederBreedId } =
    breederBreeds?.find(bb => bb.breed_id === breedId) || {};

  const availablePuppyCount = availablePuppyCountByBreedId[breedId] || 0;

  const favoriteAnalytics = {
    breed_id: breedId,
    breeder_name: breederProfileName,
    breeder_preferred_status: breederPreferredStatus,
    breeder_profile_id: Number(breederProfileId),
    puppies_available: availablePuppyCount,
    transportation: deliveryMethods.split(', ')
  };

  const iconSize = IS_MOBILE_UA ? '20px' : '24px';

  return (
    <>
      {Boolean(breedId && breederBreedId) && (
        <BreederProfileFavoriteButtonWrapper>
          <FavoriteButton
            analytics={favoriteAnalytics}
            followableTypeId={breederBreedId}
            followableType={FOLLOWABLE_TYPES.BREEDER_BREED}
            iconSize={iconSize}
            variant={
              IS_MOBILE_UA
                ? FAVORITE_BUTTON_VARIANTS.CIRCLE
                : FAVORITE_BUTTON_VARIANTS.ICON_ONLY
            }
            targetEl={
              document.getElementById('breederProfileFixedHeader') ||
              PORTAL_MOUNT_EL
            }
          />
        </BreederProfileFavoriteButtonWrapper>
      )}
    </>
  );
}
