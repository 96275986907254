import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import __DEPRECATED_defineResources from 'dpl/shared/fetching/deprecated/defineResources';
import { breederProfileBreedingProcessResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import BreederProfileAboutPuppiesSection from 'dpl/components/BreederProfile/Show/BreederProfileAboutPuppiesSection';

function BreederProfileAboutPuppiesSectionContainer(props) {
  return <BreederProfileAboutPuppiesSection {...props} />;
}

export const aboutPuppiesResourceMapper = compose(
  __DEPRECATED_defineResources(({ server: { breederProfileId } }) => ({
    breedingProcessData:
      breederProfileBreedingProcessResourceDefinition(breederProfileId)
  })),
  connect(
    (
      state,
      {
        breedingProcessData: {
          value: {
            data: {
              name: programName,
              first_name: breederFirstName,
              training_programs: trainingPrograms,
              training_description: trainingDescription,
              health_process_description: healthDescription,
              breeder_extras: extras
            }
          }
        }
      }
    ) => ({
      hasTraining: Boolean(trainingPrograms.length || trainingDescription),
      hasHealth: Boolean(healthDescription),
      hasExtras: Boolean(extras.length),
      programName,
      breederFirstName,
      trainingPrograms,
      trainingDescription,
      healthDescription,
      extras
    })
  )
);

export default aboutPuppiesResourceMapper(
  BreederProfileAboutPuppiesSectionContainer
);
