import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  MediaCategoryPropTypes,
  BreederProfilePhotographPropTypes
} from 'dpl/shared/utils/customPropTypes';
import { groupBy } from 'dpl/shared/utils';
import { isEmpty } from 'dpl/shared/utils/object';
import { PHOTO_ALBUM_TYPES } from 'dpl/shared/constants/breeder_app';
import SmartImage from 'dpl/components/SmartImage';
import HorizontalSlider from 'dpl/components/HorizontalSlider';

const PREVIEW_CATEGORIES = [
  PHOTO_ALBUM_TYPES.BREEDER,
  PHOTO_ALBUM_TYPES.BREEDER_BREED
];

function isBreederProfilePreviewCategory(category) {
  return (
    PREVIEW_CATEGORIES.includes(category.album_type) && category.show_on_profile
  );
}

function orderMediaCategoriesForThumbs(categories) {
  return categories
    .filter(isBreederProfilePreviewCategory)
    .map(cat => cat.name);
}

export default class BreederProfilePhotosSection extends Component {
  static propTypes = {
    mediaCategories: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape(MediaCategoryPropTypes))
    }).isRequired,
    onImageClick: PropTypes.func.isRequired,
    imagesData: PropTypes.shape({
      meta: PropTypes.shape({
        isSuccess: PropTypes.bool.isRequired
      }).isRequired,
      value: PropTypes.shape({
        meta: PropTypes.shape({
          total_item_count: PropTypes.number
        }).isRequired,
        data: PropTypes.arrayOf(
          PropTypes.shape(BreederProfilePhotographPropTypes)
        ).isRequired
      }).isRequired
    }).isRequired
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.imagesData.meta.isSuccess &&
      isEmpty(prevState.categoryToImages)
    ) {
      return {
        categoryToImages: groupBy(nextProps.imagesData.value.data, 'category')
      };
    }

    return null;
  }

  state = {
    categoryToImages: {}
  };

  render() {
    const {
      onImageClick,
      mediaCategories,
      imagesData: {
        value: {
          meta: { total_item_count: imageCount },
          data: [firstImage]
        }
      }
    } = this.props;

    const { categoryToImages } = this.state;

    return (
      <section className="BreederProfilePhotosSection">
        <h5 className="mb4 f5">Photos</h5>

        <HorizontalSlider containerClassName="row flex-nowrap">
          {orderMediaCategoriesForThumbs(mediaCategories.data).map(category => {
            const { 0: firstCategoryImage, length } =
              categoryToImages[category] || [];

            if (!firstCategoryImage) {
              return null;
            }

            return (
              <div className="col-auto ws-normal" key={category}>
                <button
                  type="button"
                  className="mb6-md mb2 w-100 f0 dib grow-child"
                  onClick={() => onImageClick(firstCategoryImage.id)}
                >
                  <div className="overflow-hidden br3">
                    <SmartImage
                      Tag="div"
                      className="BreederProfilePhotosSection__image bg-center cover br3 child"
                      crop
                      lazy
                      loadingClass="bg-light-gray"
                      width="267px"
                      height="178px"
                      src={firstCategoryImage.url_small_wide}
                    />
                  </div>
                  <div className="pv4">
                    <p className="f3 f4-lg mb2 fw-medium">{category}</p>
                    <p className="silver f2 f3-lg">{length} photos</p>
                  </div>
                </button>
              </div>
            );
          })}
        </HorizontalSlider>
        {firstImage && (
          <button
            type="button"
            className="primary fw-medium"
            onClick={() => onImageClick(firstImage.id)}
          >
            View {imageCount} photos
          </button>
        )}
      </section>
    );
  }
}
