import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import __DEPRECATED_defineResources from 'dpl/shared/fetching/deprecated/defineResources';
import { breederProfileBreedsWithHealthInfoResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import { isMobileUA } from 'dpl/shared/utils';
import BreederProfileHealthTestsSection from 'dpl/components/BreederProfile/Show/BreederProfileHealthTestsSection';
import MobileBreederProfileHealthTestsSection from 'dpl/components/BreederProfile/Show/MobileBreederProfileHealthTestsSection';

function BreederProfileHealthTestsSectionContainer(props) {
  return isMobileUA() ? (
    <MobileBreederProfileHealthTestsSection {...props} />
  ) : (
    <BreederProfileHealthTestsSection {...props} />
  );
}

export const healthTestsResourceMapper = compose(
  __DEPRECATED_defineResources(({ server: { breederProfileId } }) => ({
    breedsWithHealthInfoData:
      breederProfileBreedsWithHealthInfoResourceDefinition(breederProfileId)
  })),
  connect(
    ({ server: { breederDisplayName } }, { breedsWithHealthInfoData }) => {
      const breedsWithHealthInfo = [];

      const { breeds } = breedsWithHealthInfoData.value.data;

      breeds.forEach(b => {
        const { preferences, ...rest } = b;

        if (preferences && preferences.length) {
          preferences.forEach(p => {
            if (p.health_tests.length > 0) {
              breedsWithHealthInfo.push({
                ...rest,
                breed_name: `${p.description} ${b.breed_name}`,
                pluralized_breed_name: `${p.description} ${b.pluralized_breed_name}`,
                health_tests: p.health_tests,
                health_tier: p.health_tier
              });
            }
          });
        } else if (b.health_tests.length > 0) {
          breedsWithHealthInfo.push(b);
        }
      });

      return { breedsWithHealthInfo, breederDisplayName };
    }
  )
);

export default compose(healthTestsResourceMapper)(
  BreederProfileHealthTestsSectionContainer
);
