import React from 'react';
import { connect } from 'react-redux';

import BreederProfileIntroductionSection from 'dpl/components/BreederProfile/Show/BreederProfileIntroductionSection';

function BreederProfileIntroductionSectionContainer(props) {
  return <BreederProfileIntroductionSection {...props} />;
}

export default connect(({ server }) => ({
  introduction: server.introParagraph,
  breederFullName: server.breederFullName,
  breederDisplayName: server.breederDisplayName,
  breederFunFacts: server.breederFunFacts,
  breederPhotoUrl: server.breederPhotoUrl,

  // TODO: mobile only
  deliveryMethods: server.deliveryMethods,
  cityState: server.cityState,
  priceRange: server.priceRange,
  availablePuppyCount: server.availablePuppyCount
}))(BreederProfileIntroductionSectionContainer);
