import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SmartImage from 'dpl/components/SmartImage';
import DogPlaceholderSvg from 'dpl/common/components/DogPlaceholderSvg';

export default function VennImages({
  className,
  images,
  isBordered,
  isOverlapping,
  isSmall,
  maxShown,
  width,
  height,
  additionalCountClassName,
  puppy
}) {
  const shownImages = images.slice(0, maxShown);
  const remainingImageCount = images.length - shownImages.length;
  const imageClassName = isBordered ? 'ba b--white bw1' : 'bw0';

  const additionalClasses = [];

  return (
    <div
      className={classnames('VennImages', [...additionalClasses], className, {
        'VennImages--overlapping': isOverlapping,
        'VennImages--small': isSmall
      })}
    >
      <div className="flex items-center">
        <div className="VennImages__images f0">
          {shownImages.map(({ src, alt }, idx) =>
            src ? (
              <SmartImage
                Tag="div"
                crop
                lazy
                key={idx}
                src={src}
                alt={alt}
                className={classnames(
                  'VennImages__image relative br-100 cover bg-center',
                  imageClassName
                )}
                imageOnlyClassName="ba b--white br-100"
                style={{ zIndex: shownImages.length - idx }}
                width={width}
                height={height}
              />
            ) : (
              <DogPlaceholderSvg
                key={idx}
                className={classnames(
                  'VennImages__image br-100',
                  imageClassName
                )}
                width={width}
                height={height}
                puppy={puppy}
                icon
              />
            )
          )}
        </div>
        {remainingImageCount > 0 && (
          <span className={classnames('fw-medium', additionalCountClassName)}>
            {`+${remainingImageCount}`}
          </span>
        )}
      </div>
    </div>
  );
}

VennImages.propTypes = {
  className: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string
    })
  ).isRequired,
  isBordered: PropTypes.bool,
  isOverlapping: PropTypes.bool,
  isSmall: PropTypes.bool,
  maxShown: PropTypes.number,
  width: PropTypes.string,
  height: PropTypes.string,
  additionalCountClassName: PropTypes.string,
  puppy: PropTypes.bool
};

VennImages.defaultProps = {
  className: '',
  isBordered: false,
  isOverlapping: false,
  isSmall: false,
  maxShown: 3,
  width: '48px',
  height: '48px',
  additionalCountClassName: 'purple',
  puppy: false
};
