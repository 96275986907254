import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ADOPTER_APPLICATION_OPEN, BREED_ID } from 'dpl/constants/query_params';
import { pushParams } from 'dpl/actions/query_params';
import { singleBreederProfileResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import __DEPRECATED_defineResources from 'dpl/shared/fetching/deprecated/defineResources';
import BreederProfileApplyButton from 'dpl/components/BreederProfile/Show/BreederProfileApplyButton';
import usePuppiesAndLitterAvailabilityCheck from 'dpl/adoption_application/hooks/usePuppiesAndLitterAvailabilityCheck';
import isFeatureEnabled from 'dpl/util/isFeatureEnabled';
import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import useCurrentUser from 'dpl/common/hooks/useCurrentUser';

const IS_REL_BUYER_APP_BREEDER_PROFILE_ENABLED = isFeatureEnabled(
  'rel_buyer_application_breeder_profile'
);

export default function BreederProfileApplyButtonContainer() {
  const { breederProfileId, breederPreferredStatus } = useSelector(
    ({ server }) => server
  );
  const breedId = useSelector(({ queryParams }) => queryParams[BREED_ID]);

  const { availablePuppiesForBreed } =
    usePuppiesAndLitterAvailabilityCheck(breedId);

  const dispatch = useDispatch();
  const currentUser = useCurrentUser();

  const {
    isFetching,
    isSuccess,
    value: breederProfileShowData
  } = useResourceQuery({
    ...singleBreederProfileResourceDefinition(parseInt(breederProfileId, 10)),
    enabled: Boolean(breederProfileId)
  });

  const unsnoozedBreeds = breederProfileShowData.breeds.filter(b => !b.snoozed);

  const shouldShow =
    isSuccess && unsnoozedBreeds.length > 0 && availablePuppiesForBreed;

  function onClick() {
    if (IS_REL_BUYER_APP_BREEDER_PROFILE_ENABLED) {
      window.location = `${breederProfileShowData.profile_url}/contacts/buyer_application`;
    } else {
      dispatch(pushParams({ [ADOPTER_APPLICATION_OPEN]: true }));
    }
  }

  if (isFetching || !shouldShow) {
    return null;
  }

  return (
    <BreederProfileApplyButton
      breederProfileId={parseInt(breederProfileId, 10)}
      breederPreferredStatus={breederPreferredStatus}
      currentUser={currentUser}
      isLoggedIn={currentUser.isLoggedIn}
      onClick={onClick}
    />
  );
}
