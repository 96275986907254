import React from 'react';

import __DEPRECATED_defineResources from 'dpl/shared/fetching/deprecated/defineResources';
import { breederInterviewResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import BreederProfileInterviewSection from 'dpl/components/BreederProfile/Show/BreederProfileInterviewSection';

function BreederProfileInterviewSectionContainer(props) {
  return <BreederProfileInterviewSection {...props} />;
}

export const interviewResourceMapper = __DEPRECATED_defineResources(
  ({ server: { breederProfileId } }) => ({
    interview: breederInterviewResourceDefinition(breederProfileId)
  })
);

export default interviewResourceMapper(BreederProfileInterviewSectionContainer);
