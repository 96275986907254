import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  LITTER_STATES,
  LITTER_STATE_TITLES
} from 'dpl/shared/constants/shared';
import { BreederProfileLitterPropTypes } from 'dpl/shared/utils/customPropTypes';
import LitterParentsVennImages from 'dpl/breeder_dashboard/components/LitterParentsVennImages';
import Button from 'dpl/common/design_system/Button';
import HorizontalSlider from 'dpl/components/HorizontalSlider';

const { BORN } = LITTER_STATES;
const MAX_LITTERS_TO_SHOW = 3;
const SMART_IMAGE_PROPS = {
  width: '56px',
  height: '56px',
  className: 'dib flex-none',
  isBordered: true,
  isOverlapping: true
};

function getDisplayBirthDate(litter) {
  return `${litter.status === BORN ? 'Born' : 'Due'} ${
    litter.birth_date_month_year
  }`;
}

function getLitterStatus(litter) {
  return `${LITTER_STATE_TITLES[litter.status]} ${
    litter.pluralized_breed_name
  } from ${litter.parent_names}`;
}

function LitterCard({ litter, onLitterClick }) {
  return (
    <div className="BreederProfileLitterCard flex flex-column gap-24 w-100 bg-white br4 ba b--subtle-gray overflow-hidden ph4 pv4">
      <LitterParentsVennImages
        litterParents={litter.parents}
        vennImageProps={SMART_IMAGE_PROPS}
      />
      <div>
        <p className="font-serif font-20">{getLitterStatus(litter)}</p>
        <p className="font-12 tertiary mt2">
          {getDisplayBirthDate(litter)}. Ready {litter.ready_date_month_year}
        </p>
      </div>
      <Button
        data-test-id={`litter-${litter.id}`}
        size="small"
        variant="outline"
        onClick={() => {
          onLitterClick(litter.id);
        }}
      >
        View litter
      </Button>
    </div>
  );
}

LitterCard.propTypes = {
  litter: PropTypes.shape(BreederProfileLitterPropTypes).isRequired,
  onLitterClick: PropTypes.func.isRequired
};

export default function BreederProfileLittersSection({
  hasPuppies,
  isExpanded,
  litters,
  onLitterClick,
  toggle
}) {
  const hasMoreLitters = litters.length > MAX_LITTERS_TO_SHOW;
  const visibleLitters = litters.slice(
    0,
    isExpanded ? litters.length : MAX_LITTERS_TO_SHOW
  );

  return (
    <section className="BreederProfileLittersSection bg-subtle-light-gray">
      <div className="container-xxl">
        <div
          className={classnames('pv12 pv20-md', {
            'bt b--gray-300': hasPuppies
          })}
        >
          <h4 className="mb6 mb10-md font-24 md:font-32 lh-title">
            Newborn & upcoming litters
          </h4>

          {/* mobile */}
          <div className="db dn-ns">
            <HorizontalSlider
              className="BreederProfileLittersSlider"
              containerClassName="BreederProfileLittersSlider__container flex flex-nowrap w-100 gap-8"
            >
              {litters.map(litter => (
                <div key={litter.id} className="flex ws-normal">
                  <LitterCard litter={litter} onLitterClick={onLitterClick} />
                </div>
              ))}
            </HorizontalSlider>
          </div>

          {/* desktop */}
          <div className="dn flex-ns flex-column gap-8">
            {visibleLitters.map(litter => (
              <div
                key={litter.id}
                className="BreederProfileLitterSectionLineItem w-100 bg-white items-center gap-8 br4 ba b--subtle-gray overflow-hidden ph5 pv5"
              >
                <div className="flex flex-auto items-center mw-100">
                  <LitterParentsVennImages
                    litterParents={litter.parents}
                    vennImageProps={SMART_IMAGE_PROPS}
                  />
                  <p className="font-serif font-20 ml4 truncate">
                    {getLitterStatus(litter)}
                  </p>
                </div>

                <p className="font-16 tertiary">
                  {getDisplayBirthDate(litter)}
                </p>
                <p className="font-16 tertiary">
                  Ready {litter.ready_date_month_year}
                </p>

                <Button
                  data-test-id={`litter-${litter.id}`}
                  size="small"
                  variant="outline"
                  onClick={() => {
                    onLitterClick(litter.id);
                  }}
                >
                  View litter
                </Button>
              </div>
            ))}
          </div>

          {hasMoreLitters && (
            <button
              onClick={toggle}
              className="dn dib-ns underline midnight font-16 mt6"
              type="button"
            >
              {isExpanded ? 'Show less' : 'Show more'}
            </button>
          )}
        </div>
      </div>
    </section>
  );
}

BreederProfileLittersSection.propTypes = {
  hasPuppies: PropTypes.bool,
  isExpanded: PropTypes.bool.isRequired,
  litters: PropTypes.arrayOf(PropTypes.shape(BreederProfileLitterPropTypes))
    .isRequired,
  onLitterClick: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired
};

BreederProfileLittersSection.defaultProps = {
  hasPuppies: false
};
