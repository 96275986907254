import React from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';

import { ADOPTER_APPLICATION_OPEN, BREED_ID } from 'dpl/constants/query_params';
import { resetParams } from 'dpl/actions/query_params';
import OverlayAsync from 'dpl/adoption_application/components/OverlayAsync';

import usePuppiesAndLitterAvailabilityCheck from '../hooks/usePuppiesAndLitterAvailabilityCheck';

function OverlayContainer(props) {
  const breedId = useSelector(({ queryParams }) => queryParams[BREED_ID]);
  const { availablePuppiesForBreed } =
    usePuppiesAndLitterAvailabilityCheck(breedId);
  const dispatch = useDispatch();

  if (!props.isOpen) {
    return null;
  }

  if (!availablePuppiesForBreed) {
    dispatch(resetParams(ADOPTER_APPLICATION_OPEN));
    return null;
  }

  return <OverlayAsync {...props} />;
}

OverlayContainer.propTypes = {
  isOpen: PropTypes.bool
};

OverlayContainer.defaultProps = {
  isOpen: false
};

export default connect(
  ({ queryParams }) => ({
    isOpen: Boolean(queryParams[ADOPTER_APPLICATION_OPEN])
  }),
  {
    onClose: () => resetParams(ADOPTER_APPLICATION_OPEN)
  }
)(OverlayContainer);
