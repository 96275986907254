import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { DogPropTypes } from 'dpl/shared/utils/customPropTypes';
import { GENDERS } from 'dpl/shared/constants/breeder_app';
import { addQueryToUrl } from 'dpl/util/queryString';
import { BREEDING_STATUSES } from 'dpl/shared/constants/shared';
import SmartImage from 'dpl/components/SmartImage';
import DogPlaceholderSvg from 'dpl/common/components/DogPlaceholderSvg';
import HorizontalSlider from 'dpl/components/HorizontalSlider';
import TransitionMenu from 'dpl/common/components/TransitionMenu';
import Icon from 'dpl/common/components/Icon';
import HealthTierBadge from 'dpl/common/components/HealthTierBadge';
import {
  ROOT_HOST,
  BREED_HEALTH_TESTING_GUIDE_PATH
} from 'dpl/shared/constants/urls';
import { groupBy, isMobileUA } from 'dpl/shared/utils';

const IS_MOBILE = isMobileUA();

const LIGHTBULB_ICON_SIZE = IS_MOBILE ? '48px' : '56px';

export default function BreederProfileParentsSection({ dogs, onDogClick }) {
  const [selectedMenuIdx, setSelectedMenuIdx] = useState(0);

  const dogsByBreed = groupBy(dogs, 'breed_name');

  const parentDogs = dogsByBreed[Object.keys(dogsByBreed)[selectedMenuIdx]];

  const firstDogByBreed =
    dogsByBreed[Object.keys(dogsByBreed)[selectedMenuIdx]][0];

  return (
    <section className="BreederProfileParentsSection">
      <h5 className="mb3 f5">Moms &amp; dads</h5>
      <TransitionMenu
        className="bb b--light-gray fw-medium mb6 overflow-scroll no-scrollbars"
        itemClassName="pv3 light-gray purple"
        items={Object.keys(dogsByBreed)}
        selectedIdx={selectedMenuIdx}
        onItemClick={(_, idx) => setSelectedMenuIdx(idx)}
      />
      <HorizontalSlider
        className="mb6"
        iconClassName="b bg-white ph3 pv3 br-100 box-shadow-strong"
        arrowClassName="pb1"
        leftArrowClassName="BreederProfileParentsSection__leftArrow"
        rightArrowClassName="BreederProfileParentsSection__rightArrow"
        containerClassName="row flex-nowrap"
        persistentArrows
      >
        {parentDogs.map(dog => (
          <div key={dog.id} className="col-auto ws-normal">
            <a
              className="w-100 f0 dib grow-child"
              href={addQueryToUrl({ dog_id: dog.id }, '')}
              onClick={e => {
                e.preventDefault();
                onDogClick(dog.id);
              }}
            >
              <div className="mb2 overflow-hidden br3">
                {dog.profile_photo_url ? (
                  <SmartImage
                    crop
                    lazy
                    Tag="div"
                    width="200px"
                    height="150px"
                    className="mw-100 min-w-100 cover bg-center br3 child"
                    loadingClass="bg-light-gray"
                    src={dog.profile_photo_url}
                  />
                ) : (
                  <DogPlaceholderSvg
                    className="bw1 br1 b--near-white ba child hover-bg-near-white"
                    width="200px"
                    height="150px"
                  />
                )}
              </div>
              <div className="f3 flex w0 min-w-100 nowrap mb1">
                <p className="truncate">
                  <span className="fw-medium">{dog.name},</span>{' '}
                  {dog.gender === GENDERS.FEMALE ? 'Mom' : 'Dad'}
                </p>
                {dog.breeding_status &&
                  dog.breeding_status !== BREEDING_STATUSES.ACTIVE && (
                    <p>&nbsp;&bull; {dog.breeding_status}</p>
                  )}
              </div>
              {dog.health_tier_title && (
                <HealthTierBadge healthTierTitle={dog.health_tier_title} />
              )}
            </a>
          </div>
        ))}
      </HorizontalSlider>
      <div className="row flex mb4 ph6 pv6 ba br3 b--light-gray items-center">
        <div className="col-md-10 flex items-center-ns mb4 mb0-sm">
          <Icon
            className="ph3 pv3 mr6 br-100 bg-yellow dark-gray"
            height={LIGHTBULB_ICON_SIZE}
            width={LIGHTBULB_ICON_SIZE}
            name="lightbulb"
          />
          <p>
            Health testing is performed on breeding dogs to reduce the risk of
            heritable diseases or conditions.
          </p>
        </div>
        <a
          className="col-md-2 f2 fw-medium pv2 ph9 br3 button button--near-white black nowrap"
          target="_blank"
          rel="noreferrer noopener"
          href={`${ROOT_HOST}/breeds/${firstDogByBreed.breed_slug}${BREED_HEALTH_TESTING_GUIDE_PATH}`}
        >
          Learn more
        </a>
      </div>
    </section>
  );
}

BreederProfileParentsSection.propTypes = {
  dogs: PropTypes.arrayOf(PropTypes.shape(DogPropTypes)).isRequired,
  onDogClick: PropTypes.func.isRequired
};
