import React from 'react';

import __DEPRECATED_defineResources from 'dpl/shared/fetching/deprecated/defineResources';
import BreederProfileDeliverySection from 'dpl/components/BreederProfile/Show/BreederProfileDeliverySection';
import { deliveryMethodsResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';

function BreederProfileDeliverySectionContainer(props) {
  return <BreederProfileDeliverySection {...props} />;
}

export default __DEPRECATED_defineResources(({ server }) => ({
  deliveryMethods: deliveryMethodsResourceDefinition(server.breederProfileId)
}))(BreederProfileDeliverySectionContainer);
