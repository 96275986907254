import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'dpl/common/components/Icon';
import ClampedMultiLineText from 'dpl/common/components/ClampedMultiLineText';

function BreederProfileAboutPuppiesSectionItem({ icon, title, text }) {
  return (
    <div className="BreederProfileAboutPuppiesSectionItem">
      <div className="flex">
        <div>
          <Icon name={icon} />
        </div>
        <div className="flex-auto pl2">
          <p className="fw-medium mb2">{title}</p>
          {text && (
            <ClampedMultiLineText numOfLines={4} lineHeight={24} text={text} />
          )}
        </div>
      </div>
    </div>
  );
}

BreederProfileAboutPuppiesSectionItem.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default function BreederProfileAboutPuppiesSection({
  hasTraining,
  hasHealth,
  hasExtras,
  programName,
  breederFirstName,
  trainingDescription,
  trainingPrograms,
  healthDescription,
  extras
}) {
  const trainingProgramsStr = trainingPrograms
    .map(({ name }) => name)
    .join(', ');

  const trainingSection = {
    icon: 'ball',
    name: 'training',
    title: 'Enrichment & Socialization',
    content: hasTraining
      ? [
          trainingDescription,
          trainingProgramsStr &&
            `I utilize the following enrichment methods: ${trainingProgramsStr}.`
        ]
          .filter(Boolean)
          .join(' ')
      : `
        Breeders often put a lot of effort into the enrichment of
        their puppies including things like early training or socialization
        with children and other animals. ${programName} has not yet
        provided information about their practices
        so please contact them for more details.
      `
  };

  const healthSection = {
    icon: 'stethoscope',
    name: 'health',
    title: 'Health',
    content: hasHealth
      ? healthDescription
      : `
        The breeders on Good Dog ensure that their puppies are in
        good health before they go to their new homes.
        They are given appropriate shots and are dewormed or
        have a negative fecal exam before going home. ${programName} has
        not yet provided more information about their specific
        health practices so please contact them for more details.
      `
  };

  const extrasSection = {
    icon: 'gift',
    name: 'extras',
    title: 'Included with Puppy',
    content: hasExtras
      ? `We send our puppies home with a ${extras
          .map(({ name }) => name)
          .join(', ')}.`
      : `
        Additional benefits are often included with your puppy such as a small
        bag of food or a toy with their littermates' scent. ${programName}
        has not yet provided information about what's included with their
        puppies so please contact them for more details.
      `
  };

  const guaranteeSection = {
    icon: 'health-badge',
    name: 'guarantee',
    title: 'Contracts and Health Guarantees',
    content: `
              ${breederFirstName} may offer a written contract or health guarantee if you
              purchase a puppy from them. A written contract protects both you and ${breederFirstName}
              and ensures you have a shared understanding of the terms of your puppy purchase. If ${breederFirstName}
              offers a contract or guarantee, the specific terms are determined by them. If you have any questions,
              feel free to reach out to ${breederFirstName} directly.
            `
  };

  return (
    <section className="BreederProfileAboutPuppiesSection">
      <h5 className="mb6 d-none d-lg-block f5">About the puppies</h5>

      <div className="row no-gutters nb10-md nb4">
        <div className="col-md-6">
          <div className="mb10-md mb4 pr5-md">
            <BreederProfileAboutPuppiesSectionItem
              icon={trainingSection.icon}
              title={trainingSection.title}
              text={trainingSection.content}
            />
          </div>
          <div className="mb10-md mb4 pr5-md">
            <BreederProfileAboutPuppiesSectionItem
              icon={extrasSection.icon}
              title={extrasSection.title}
              text={extrasSection.content}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb10-md mb4 pl5-md">
            <BreederProfileAboutPuppiesSectionItem
              icon={healthSection.icon}
              title={healthSection.title}
              text={healthSection.content}
            />
          </div>
          <div className="mb10-md mb4 pl5-md">
            <BreederProfileAboutPuppiesSectionItem
              icon={guaranteeSection.icon}
              title={guaranteeSection.title}
              text={guaranteeSection.content}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

BreederProfileAboutPuppiesSection.propTypes = {
  programName: PropTypes.string.isRequired,
  breederFirstName: PropTypes.string.isRequired,
  trainingDescription: PropTypes.string,
  trainingPrograms: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string.isRequired })
  ).isRequired,
  healthDescription: PropTypes.string,
  extras: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  hasTraining: PropTypes.bool.isRequired,
  hasHealth: PropTypes.bool.isRequired,
  hasExtras: PropTypes.bool.isRequired
};

BreederProfileAboutPuppiesSection.defaultProps = {
  trainingDescription: '',
  healthDescription: ''
};
