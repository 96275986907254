import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import __DEPRECATED_defineResources from 'dpl/shared/fetching/deprecated/defineResources';
import { pushParams } from 'dpl/actions/query_params';
import { LITTER_ID, PUPPY_ID } from 'dpl/constants/query_params';
import { breederProfilePuppiesResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import { nameUnnamedPuppies } from 'dpl/shared/utils/breeder_app';
import BreederProfileCurrentPuppiesSection from 'dpl/components/BreederProfile/Show/BreederProfileCurrentPuppiesSection';

function BreederProfileCurrentPuppiesSectionContainer(props) {
  return <BreederProfileCurrentPuppiesSection {...props} />;
}

export const currentPuppiesResourceMapper = compose(
  __DEPRECATED_defineResources(({ server: { breederProfileId } }) => ({
    puppiesData: breederProfilePuppiesResourceDefinition(breederProfileId)
  })),
  connect((state, { puppiesData }) => ({
    validPuppies: nameUnnamedPuppies(puppiesData.value.data)
  }))
);

export default compose(
  currentPuppiesResourceMapper,
  connect(null, {
    onPuppyClick: (puppyId, litterId) =>
      pushParams(
        {
          [LITTER_ID]: litterId,
          [PUPPY_ID]: puppyId
        },
        { hash: true }
      )
  })
)(BreederProfileCurrentPuppiesSectionContainer);
