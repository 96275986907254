import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import __DEPRECATED_defineResources from 'dpl/shared/fetching/deprecated/defineResources';
import { breederProfileDogsResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import { pushParams } from 'dpl/actions/query_params';
import { DOG_ID } from 'dpl/constants/query_params';
import BreederProfileParentsSection from 'dpl/components/BreederProfile/Show/BreederProfileParentsSection';

function BreederProfileParentsSectionContainer(props) {
  return <BreederProfileParentsSection {...props} />;
}

export const parentsResourceMapper = compose(
  __DEPRECATED_defineResources(({ server: { breederProfileId } }) => ({
    parentsData: breederProfileDogsResourceDefinition(breederProfileId)
  })),
  connect((state, { parentsData }) => ({
    dogs: parentsData.value.data.dogs
  }))
);

export default compose(
  connect(null, {
    onDogClick: dogId => pushParams({ [DOG_ID]: dogId }, { hash: true })
  }),
  parentsResourceMapper
)(BreederProfileParentsSectionContainer);
