import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { PHOTO_ALBUM_MAX_WIDTH } from 'dpl/constants/photo_album';
import { isMobileUA, debounce } from 'dpl/shared/utils';
import SmartImage from 'dpl/components/SmartImage';

export default class ZoomingPhoto extends Component {
  static propTypes = {
    isDisplayed: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    retainAspectRatio: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    smartImageProps: PropTypes.object,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired
    }).isRequired,
    albumPadding: PropTypes.shape({
      top: PropTypes.number.isRequired,
      bottom: PropTypes.number.isRequired,
      right: PropTypes.number.isRequired
    })
  };

  static defaultProps = {
    isDisplayed: false,
    retainAspectRatio: false,
    albumPadding: { top: 0, bottom: 0, right: 0 },
    smartImageProps: {}
  };

  state = {
    isDisplayed: false,
    isTransitioning: false
  };

  imageContainerRef = createRef();
  buttonRef = createRef();
  imageRatio = null;
  originalWidth = null;
  originalHeight = null;
  initialImagePosition = {};

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    // Only for backward animation of collage images. We don't want to
    // change state.isDisplayed unless an image was clicked, displayed, and
    // should now transition back bc we're exiting the photo album
    const shouldAnimateBack =
      nextProps.isDisplayed === false && this.state.isDisplayed;

    if (shouldAnimateBack) {
      this.setState({
        isDisplayed: false,
        isTransitioning: true
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { isTransitioning, isDisplayed } = this.state;

    return (
      nextState.isDisplayed !== isDisplayed ||
      nextState.isTransitioning !== isTransitioning
    );
  }

  componentDidUpdate() {
    const { isTransitioning, isDisplayed } = this.state;

    if (!isTransitioning && isDisplayed) {
      this.props.onClick(this.props.image.id);
    }
  }

  setImageRatio = target => {
    this.imageRatio = target.naturalWidth / target.naturalHeight;
    this.originalWidth = target.naturalWidth;
    this.originalHeight = target.naturalHeight;
    this.setInitialImagePosition();
    this.forceUpdate();
  };

  handleResize = debounce(() => {
    this.initialImagePosition = {};
    this.unlockButtonPosition();
    this.forceUpdate(() => {
      window.requestAnimationFrame(() => {
        this.setInitialImagePosition();
        this.forceUpdate();
      });
    });
  }, 100);

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  // so we know where to transition it back to
  setInitialImagePosition() {
    const { top, left } =
      this.imageContainerRef.current.getBoundingClientRect();
    const { clientHeight, clientWidth } =
      this.imageContainerRef.current.querySelector('img') ||
      this.imageContainerRef.current;

    this.initialImagePosition = {
      top: `${top}px`,
      left: `${left}px`,
      width: `${clientWidth}px`,
      height: `${clientHeight}px`
    };
  }

  unlockButtonPosition() {
    Object.assign(this.buttonRef.current.style, { width: '', height: '' });
  }

  lockButtonPosition() {
    const { clientWidth, clientHeight } = this.buttonRef.current;
    Object.assign(this.buttonRef.current.style, {
      width: `${clientWidth}px`,
      height: `${clientHeight}px`
    });
  }

  computeImageElStyles() {
    const { albumPadding } = this.props;

    if (this.state.isDisplayed) {
      let height = Math.min(
        window.innerHeight - (albumPadding.top + albumPadding.bottom),
        this.originalHeight || window.Infinity
      );

      let width = Math.min(
        Math.min(window.innerWidth, PHOTO_ALBUM_MAX_WIDTH) - albumPadding.right,
        this.originalWidth || window.Infinity
      );

      if (height * this.imageRatio > width) {
        height = width / this.imageRatio;
      } else {
        width = height * this.imageRatio;
      }

      return {
        marginTop: `-${
          (height + albumPadding.bottom - albumPadding.top) / 2
        }px`,
        marginLeft: `-${(width + albumPadding.right) / 2}px`,
        width: `${width}px`,
        height: `${height}px`
      };
    }

    return {
      ...this.initialImagePosition
    };
  }

  handleClick = isMobileUA()
    ? () => this.props.onClick(this.props.image.id)
    : () => {
        this.lockButtonPosition();
        this.setInitialImagePosition();
        window.requestAnimationFrame(() => {
          // must update once so we "force" image to its current position before we
          // make it "position: fixed"
          this.forceUpdate(() => {
            window.requestAnimationFrame(() => {
              this.setState({
                isDisplayed: true,
                isTransitioning: true
              });
            });
          });
        });
      };

  handleTransitionEnd = e => {
    if (e.propertyName === 'left') {
      this.setState({ isTransitioning: false });
    }
  };

  render() {
    const { isDisplayed, isTransitioning } = this.state;
    const { image, retainAspectRatio, smartImageProps } = this.props;

    return (
      <button
        type="button"
        onClick={this.handleClick}
        className="ZoomingPhoto h-100 w-100 relative db"
        ref={this.buttonRef}
      >
        <div
          onTransitionEnd={this.handleTransitionEnd}
          style={this.computeImageElStyles()}
          ref={this.imageContainerRef}
          className={classnames(
            'ZoomingPhoto__imageContainer bg-light-gray h-100',
            {
              'ZoomingPhoto__imageContainer--displayed': isDisplayed,
              'ZoomingPhoto__imageContainer--transitioning': isTransitioning
            }
          )}
        >
          <SmartImage
            lazy
            Tag={retainAspectRatio ? 'img' : 'div'}
            src={image.url}
            className={classnames('cover bg-center h-100', {
              'mw-none': retainAspectRatio && !isDisplayed && !isTransitioning,
              'min-w-100': retainAspectRatio && isDisplayed,
              'cover bg-center w-100': !retainAspectRatio
            })}
            loadingClass="ZoomingPhoto__image--loading bg-light-gray"
            onLoad={this.setImageRatio}
            {...smartImageProps}
          />
        </div>
      </button>
    );
  }
}
