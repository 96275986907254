import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { isMobileUA } from 'dpl/shared/utils';
import TransitionMenu from 'dpl/common/components/TransitionMenu';

const isMobile = isMobileUA();
export default function BreederProfileAboutBreedsSection({
  breedsWithPreferences
}) {
  const [selectedMenuIdx, setSelectedMenuIdx] = useState(0);

  const { preference_options: preferenceOptions } =
    breedsWithPreferences[selectedMenuIdx];

  const numberOfPreferences = Object.keys(preferenceOptions).length;

  return (
    <section className="BreederProfileAboutBreedsSection">
      {!isMobile && (
        <h5 className="mb3 f5">
          {breedsWithPreferences.length === 1
            ? 'About my breed'
            : 'About my breeds'}
        </h5>
      )}
      <TransitionMenu
        className="mid-gray fw-medium overflow-scroll no-scrollbars"
        itemClassName="pv3"
        items={breedsWithPreferences.map(b => b.name)}
        selectedIdx={selectedMenuIdx}
        onItemClick={(_, idx) => setSelectedMenuIdx(idx)}
        color="near-black"
        hideBorder
      />

      <div className="w-100 ba b--lightest-gray br4 row no-gutters mb4">
        {preferenceOptions.map((po, idx) => (
          <div
            key={idx}
            className={classnames('col-12 pv3 b--lightest-gray', {
              'col-lg-6': preferenceOptions.length > 1,
              'br-l': idx % 2 === 0,
              bb: idx < numberOfPreferences - 1,
              'bb-0-l':
                idx >= numberOfPreferences + (numberOfPreferences % 2) - 2
            })}
          >
            <div className="ph3">
              <div
                className={classnames('fw-medium fl', {
                  'w-30': isMobile,
                  w10: !isMobile
                })}
              >
                {po.display_label}
              </div>
              <div className="mw-70 fl">
                {po.formatted_preference_values.join(', ')}
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

BreederProfileAboutBreedsSection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  breedsWithPreferences: PropTypes.array.isRequired
};
