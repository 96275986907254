import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import __DEPRECATED_defineResources from 'dpl/shared/fetching/deprecated/defineResources';
import { breederProfileBreedsWithAllResourceDefinitions } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import BreederProfileAboutBreedsSection from 'dpl/components/BreederProfile/Show/BreederProfileAboutBreedsSection';

function BreederProfileAboutBreedsSectionContainer(props) {
  return <BreederProfileAboutBreedsSection {...props} />;
}

export const preferencesResourceMapper = compose(
  __DEPRECATED_defineResources(({ server: { breederProfileId } }) => ({
    breedsWithPreferencesData:
      breederProfileBreedsWithAllResourceDefinitions(breederProfileId)
  })),
  connect((_, { breedsWithPreferencesData }) => ({
    breedsWithPreferences: breedsWithPreferencesData.value.data
  }))
);

export default preferencesResourceMapper(
  BreederProfileAboutBreedsSectionContainer
);
