import React from 'react';
import PropTypes from 'prop-types';

import { BreederProfileInterviewPropTypes } from 'dpl/shared/utils/customPropTypes';
import { capitalize } from 'dpl/shared/utils';
import ClampedMultiLineText from 'dpl/common/components/ClampedMultiLineText';
import SmartImage from 'dpl/components/SmartImage';
import Icon from 'dpl/common/components/Icon';

export default function BreederProfileInterviewSection({ interview }) {
  const {
    answers,
    vetted,
    breeder_full_name: breederFullName,
    formatted_certifications: certifications,
    formatted_clubs: clubs,
    website_url: websiteUrl,
    instagram_url: instagramUrl,
    facebook_url: facebookUrl,
    photo: { url: interviewPhotoUrl }
  } = interview.value.data;

  const links = [
    {
      icon: 'link',
      title: 'Website',
      url: websiteUrl
    },
    {
      icon: 'instagram',
      title: 'Instagram',
      url: instagramUrl
    },
    {
      icon: 'facebook',
      title: 'Facebook',
      url: facebookUrl
    }
  ].filter(({ url }) => Boolean(url));

  return (
    <section className="BreederProfileInterviewSection">
      <h5 className="mb6 f5 d-none d-md-block">Meet the breeder</h5>

      <div className="row flex-md-row-reverse">
        <div className="col-md-4">
          {interviewPhotoUrl && (
            <div className="relative f0 mb4">
              <SmartImage
                alt={breederFullName}
                className="mw-100 min-w-100 br3"
                loadingClass="bg-light-gray"
                src={interviewPhotoUrl}
              />
              {vetted && (
                <Icon
                  name="badge"
                  width="48px"
                  height="48px"
                  className="ml2 primary absolute bottom-0 right-0 pv2 ph2 mr2 mb2 bg-white br-100"
                />
              )}
            </div>
          )}
          <p className="f4 f5-lg fw-medium flex items-center-l mb4">
            {breederFullName}
          </p>
          <div>
            {certifications && (
              <div>
                <p>Certifications &amp; Recognitions</p>
                <div className="mb3 black-50">
                  <ClampedMultiLineText
                    text={capitalize(certifications)}
                    numOfLines={2}
                    lineHeight={24}
                  />
                </div>
              </div>
            )}

            {clubs && (
              <div>
                <p>Clubs</p>
                <div className="mb3 black-50">
                  <ClampedMultiLineText
                    text={clubs}
                    numOfLines={2}
                    lineHeight={24}
                  />
                </div>
              </div>
            )}

            {links.length > 0 && (
              <div>
                <p>Links</p>
                <p className="mb6">
                  {links.map(({ title, url }, idx) => (
                    <span key={url}>
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href={url}
                        className="underline"
                      >
                        {title}
                      </a>
                      {idx !== links.length - 1 && ', '}
                    </span>
                  ))}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="col-md-8">
          <div className="pr10-md">
            <ul>
              {answers.map(({ id, question, answer }) => (
                <li key={id} className="mb6">
                  <p className="mb2 fw-medium">{question}</p>
                  {answer && (
                    <ClampedMultiLineText
                      text={answer}
                      numOfLines={4}
                      lineHeight={24}
                    />
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

BreederProfileInterviewSection.propTypes = {
  interview: PropTypes.shape({
    value: PropTypes.shape({
      data: PropTypes.shape(BreederProfileInterviewPropTypes)
    })
  }).isRequired
};
