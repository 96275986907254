import React, { useMemo } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import { isGreaterThanBreakpoint } from 'dpl/util/grid';
import ClickableDiv from 'dpl/common/components/ClickableDiv';
import SmartImage from 'dpl/components/SmartImage';
import MoreImagesCount from 'dpl/testimonials/components/MoreImagesCount';
import { photographsResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import { createBreederProfilePaginationAction } from 'dpl/containers/BreederProfile/Show/BreederProfilePhotoAlbumContainer';

const isGreaterThanSmall = isGreaterThanBreakpoint('sm');
const imageHeight = isGreaterThanSmall ? '176px' : '74px';
const imageWidth = isGreaterThanSmall ? '276px' : '74px';
const maxPhotosShown = isGreaterThanSmall ? 1 : 4;

export default function BreederProfileVerifiedOwnersPhotos() {
  const { verifiedOwnerMediaCategoryId, breederProfileId } = useSelector(
    ({ server }) => server
  );
  const photographsData = useResourceQuery(
    photographsResourceDefinition(breederProfileId)
  );
  const verifiedOwnerPhotos = useMemo(
    () =>
      photographsData.value.data.filter(
        p => p.media_category_id === verifiedOwnerMediaCategoryId
      ),
    [photographsData.value.data.length]
  );
  const verifiedOwnersPhotoCount =
    photographsData.value.meta.testimonial_photo_count;
  const photosNotShownCount = Math.max(
    verifiedOwnerPhotos.length - maxPhotosShown,
    0
  );
  const dispatch = useDispatch();

  function openPhotoAlbum(photo) {
    dispatch(createBreederProfilePaginationAction(photo.id));
  }

  if (!verifiedOwnersPhotoCount) return null;

  return (
    <div className="BreederProfileVerifiedOwnersPhotos">
      <div
        className={classnames({
          'flex flex-column-reverse ba b--light-gray br2 pv3 ph3':
            !isGreaterThanSmall
        })}
      >
        <div className="max-content relative mb2">
          <div className="flex f0">
            {verifiedOwnerPhotos.slice(0, maxPhotosShown).map((photo, idx) => (
              <ClickableDiv
                key={photo.id}
                className={classnames('overflow-hidden br3', {
                  ml1: idx !== 0
                })}
                onClick={() => openPhotoAlbum(photo)}
              >
                <SmartImage
                  Tag="div"
                  src={photo.url}
                  className="bg-center cover"
                  width={imageWidth}
                  height={imageHeight}
                />
              </ClickableDiv>
            ))}
          </div>
          {!!photosNotShownCount && (
            <div className="absolute right-0 bottom-0 mr2 mb2">
              <MoreImagesCount count={photosNotShownCount} />
            </div>
          )}
        </div>
        <p className="mb2 fw-medium">Owner Photos</p>
      </div>
    </div>
  );
}
