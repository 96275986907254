import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import { breedRelationsResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import HorizontalSlider from 'dpl/components/HorizontalSlider';
import SmartImage from 'dpl/components/SmartImage';
import SmartLink from 'dpl/components/SmartLink';

const smartImageProps = {
  Tag: 'div',
  className: 'w-100 h-100 bg-center cover',
  crop: true,
  loadingClass: 'bg-light-gray'
};

export default function RelatedBreedsModule({ className }) {
  const { server, queryParams } = useSelector(state => state);
  const {
    breeds = [{}],
    citySlug = '',
    preappliedFilters,
    nonStandard
  } = server;

  const breedId = server.breedId || queryParams.breed_id || breeds[0]?.id;
  const stateSlug = preappliedFilters
    ? preappliedFilters.stateSlug
    : server.stateSlug;

  const {
    value: { data: relatedBreeds }
  } = useResourceQuery({
    ...breedRelationsResourceDefinition(
      breedId,
      nonStandard,
      citySlug,
      stateSlug
    ),
    auto: Boolean(breedId)
  });

  if (!relatedBreeds.length) {
    return null;
  }

  return (
    <div className={classnames('RelatedBreedsModule', className)}>
      <h5 className="font-20 md:font-24 fw-medium mb6 mb8-md">
        Related breeds
      </h5>
      <HorizontalSlider
        containerClassName="RelatedBreedsModuleSlider__container flex flex-nowrap gap-8 md:gap-24 ph6 ph0-sm"
        className="RelatedBreedsModuleSlider nl6 nr6 nl0-sm nr0-sm"
        iconClassName="secondary bg-white ph2 pv2 br-100 ba b--hairline"
        iconName="fetch-chevron"
      >
        {relatedBreeds.map(breed => {
          const {
            availability_count_text: availabilityCountText,
            breed_overview_page_url: breedOverviewPageUrl,
            id,
            name,
            secondary_photo_url: photoUrl,
            url
          } = breed;

          return (
            <div className="flex ws-normal" key={id}>
              <div
                className="RelatedBreedCard flex flex-column ba b--subtle-gray br3 overflow-hidden z-0"
                style={{ minWidth: '335px' }}
              >
                <div className="aspect-ratio aspect-ratio--1x1">
                  <div className="aspect-ratio--object">
                    <SmartImage src={photoUrl} {...smartImageProps} />
                  </div>
                </div>
                <div className="tc pv4 ph4 ">
                  <p className="RelatedBreedCard__breedName font-serif font-24 fw-medium">
                    {name}
                  </p>
                  {!window.__DPL_BOT_UA && availabilityCountText && (
                    <p className="font-16 secondary mt2">
                      {availabilityCountText}
                    </p>
                  )}
                </div>
                <div className="flex bt b--subtle-gray mt-auto">
                  <SmartLink
                    to={url}
                    className="font-16 fw-medium midnight tc pv4 ph4 flex-auto flex-basis-0"
                  >
                    View puppies
                  </SmartLink>
                  {breedOverviewPageUrl && (
                    <SmartLink
                      to={breedOverviewPageUrl}
                      className="font-16 fw-medium midnight tc pv4 ph4 bl b--subtle-gray flex-auto flex-basis-0"
                      target="_blank"
                    >
                      Learn about breed
                    </SmartLink>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </HorizontalSlider>
    </div>
  );
}

RelatedBreedsModule.propTypes = {
  className: PropTypes.string
};

RelatedBreedsModule.defaultProps = {
  className: null
};
