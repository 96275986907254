import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import __DEPRECATED_defineResources from 'dpl/shared/fetching/deprecated/defineResources';
import BreederProfilePhotosSection from 'dpl/components/BreederProfile/Show/BreederProfilePhotosSection';
import { photographsResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import { createBreederProfilePaginationAction } from 'dpl/containers/BreederProfile/Show/BreederProfilePhotoAlbumContainer';

function BreederProfilePhotosSectionContainer(props) {
  return <BreederProfilePhotosSection {...props} />;
}

export const photosResourceMapper = __DEPRECATED_defineResources(
  ({ server, queryParams }) => ({
    imagesData: photographsResourceDefinition(server.breederProfileId, {
      breed_id: queryParams.breed_id
    })
  })
);

export default compose(
  connect(null, {
    onImageClick: createBreederProfilePaginationAction
  }),
  photosResourceMapper,
  connect(({ server }) => ({ mediaCategories: server.mediaCategories }))
)(BreederProfilePhotosSectionContainer);
